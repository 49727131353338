import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'
import ButtonDefault from '../../elements/ButtonDefault'

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.xl};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.xml};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.xl};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.l};
  }
`

const Item = styled.div`
  border-bottom: 2px solid black;
`

const Question = styled.button`
  & h3 {
    color: ${({ theme }) => theme.color.face.main};
    font-weight: ${({ theme }) => theme.font.weight.l};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.xm};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.mx};
    }
  }

  &:hover h3 {
    text-decoration: underline;
  }
`

const Answer = styled(motion.div)`
  overflow: hidden;
  height: 0;
`

export default function FAQ({
  questions,
  title,
  align = 'center',
  className = undefined,
  showMore = false,
}) {
  const [active, setActive] = React.useState(-1)

  return (
    <section id="faq">
      <div className="container mb-5 pb-5">
        <div className={className}>
          <div
            className={`row${
              align === 'center' ? ' justify-content-center' : ''
            }`}
          >
            <div className="col-md-10 col-lg-8">
              {title && (
                <Title className="text-center mb-4 mb-md-5">
                  Veelgestelde vragen
                </Title>
              )}
              <div>
                {questions.map((question, index) => (
                  <Item key={index} className="mb-2 mb-md-3">
                    <Question
                      type="button"
                      className="d-flex align-items-center justify-content-between w-100 py-2"
                      onClick={() => {
                        setActive(index === active ? -1 : index)
                      }}
                    >
                      <h3 className="text-left">{question.question}</h3>
                      <motion.svg
                        animate={index === active ? 'open' : 'closed'}
                        variants={{
                          open: { rotate: 90 },
                          closed: { rotate: 0 },
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M8.2101 5.29018C8.11638 5.38315 8.04198 5.49375 7.99121 5.61561C7.94044 5.73746 7.91431 5.86817 7.91431 6.00018C7.91431 6.13219 7.94044 6.2629 7.99121 6.38476C8.04198 6.50662 8.11638 6.61722 8.2101 6.71018L12.7901 11.2902C12.8838 11.3831 12.9582 11.4937 13.009 11.6156C13.0598 11.7375 13.0859 11.8682 13.0859 12.0002C13.0859 12.1322 13.0598 12.2629 13.009 12.3848C12.9582 12.5066 12.8838 12.6172 12.7901 12.7102L8.2101 17.2902C8.11638 17.3831 8.04198 17.4937 7.99121 17.6156C7.94044 17.7375 7.91431 17.8682 7.91431 18.0002C7.91431 18.1322 7.94044 18.2629 7.99121 18.3848C8.04198 18.5066 8.11638 18.6172 8.2101 18.7102C8.39747 18.8964 8.65092 19.001 8.9151 19.001C9.17929 19.001 9.43274 18.8964 9.6201 18.7102L14.2101 14.1202C14.7719 13.5577 15.0875 12.7952 15.0875 12.0002C15.0875 11.2052 14.7719 10.4427 14.2101 9.88018L9.6201 5.29018C9.43274 5.10393 9.17929 4.99939 8.9151 4.99939C8.65092 4.99939 8.39747 5.10393 8.2101 5.29018Z"
                          fill="black"
                        />
                      </motion.svg>
                    </Question>
                    <Answer
                      animate={index === active ? 'open' : 'closed'}
                      variants={{
                        open: { height: 'auto' },
                        closed: { height: 0 },
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="px-1 px-md-2">
                        {parse(question.answer)}
                      </div>
                    </Answer>
                  </Item>
                ))}
              </div>

              {showMore && (
                <div className="mt-5 d-flex justify-content-center">
                  <ButtonDefault to="/faq/" external isAnchor>
                    Bekijk alle vragen
                  </ButtonDefault>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
